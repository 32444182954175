'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: FR (French; français)
     */
    $.extend($.validator.messages, {
        'invalid': 'Veuillez saisir des données valides',
        'legalDate': 'Vous devez avoir au moins 18 ans pour pouvoir commander sur la boutique Jack Wolfskin.',
        'invalidZip': 'Veuillez saisir un code postal valide',
        'invalidPassword': 'Le mot de passe doit contenir au minimum un caractère spécial, une majuscule, un chiffre et comporter au moins 8 caractères.',
        'passwordsDontMatch': 'Vos mots de passe ne correspondent pas.',
        'streetMissingHouseNumber': 'Vous n’avez pas indiqué de numéro de rue, est-ce correct ?',
        'selectReturnReason': 'Veuillez sélectionner un motif de retour pour continuer'
    });
    return $;
}));
